.o-form {

}

.o-form__fieldset {
	border: 0;
	margin: 0;
	padding: 0;

	& > .parsley-errors-list {
		display: none;
	}

	small {
		display: inline-block;
		margin-top: 2em;
	}
}

.o-form__group {
	display: flex;
	padding: 0.75em 0;
	position: relative;

	&--message {
		color: $black;
	}

	&--file-input {
		display: none; 

		html.fileinput & {
			display: flex;
		}
	}

	&--spacer {
		padding-bottom: 2em;

		// padding-bottom: 1.875em;
		// @include min-width(large) {
		// 	padding-bottom: 1.75em;
		// }
	}
}

.o-form__label {
	font-family: $secondary-font;
	// min-width: 8.125em;
	width: 25%;
	display: inline-block;

	&--file {
		text-decoration: underline;
	}
}

.o-form__input {
	width: 75%;

	&--h {
		display: none;
	}

	&[type="text"] {
		// background: red;
		border-bottom: 1px solid $white;
		border-radius: 0;
		// color: $black;
	}

	&[type="file"] {
		// display: none;
	}	

	&[type='checkbox'], 
	&[type='radio'] {
		background: transparent;
		border: none;
		float: left;
		margin-right: .75em;
		width: auto;

		&:checked, 
		&:not(:checked) {
			appearance: none;
			background: transparent;
			border: none;
			border-radius: 0;
			display: none;
			margin: 0;
			padding: 0;
			position: relative;
			visibility: hidden;

			&  + label::before {
				background: $vivid-orange;
				border: 1px solid currentColor;
				content: '';
				display: inline-block;
				height: 25px;
				margin-right: .6em;
				width: 25px;
			}
		}

		& + label {
			align-items: center;
			cursor: pointer;
			display: flex;
			height: 25px;
			line-height: 1;
			margin-right: 1.2em;

			a {
				text-decoration: underline;
			}
		}	

		&:hover + label::before {
			background:rgba(255,255,255, .4);
			box-shadow: inset 0 0 0 5px $vivid-orange;
		}

		&:checked + label::before {
			background: currentColor;
			box-shadow: inset 0 0 0 5px $vivid-orange;
		}
	}

	&[type=radio]:checked + label::before, 
	&[type=radio]:not(:checked) + label::before {
		border-radius: 30px;
	}
}


.o-form__textarea {
	// background: blue;
	border-bottom: 1px solid $white;
	border-radius: 0;
	height: 23px; 
	overflow: hidden;
	padding: 2px 0 2px; 
	resize: none;
	width: 75%;
}

.o-form__button {
	border: 1px solid #fff;
	cursor: pointer;
	padding: 15px;
	outline: none;
	width: 100%;

	&:hover, 	
	&:focus {
		background:rgba(255,255,255, .05);
	}
}

.parsley-error {
	color: rgb(255, 201, 175);
}

.parsley-errors-list {
    bottom: -5px;
	color: rgb(255, 201, 175);
    font-size: .7em;
    left: 25%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
}    