html {
	// http://codepen.io/ibanez182/pen/reKOEK
	$f-base: 16px;
	$f-min:  13px;
	$f-max:  16px;
	$w-min:  320px;
	$w-max:  1300px;

	font-size: ($f-min / $f-base * 1em);

	/**
	* 1. Apply rule to all browsers
	* 2. Override WebKit browsers, because Safari needs percentages to work
	* 3. Override IE/Edge, because Edge applies previous -webkit-calc() and 
	*    won't work with percentages
	*/

	@media screen and (min-width: ($w-min / $f-base * 1em)) {
		font-size: calc(#{($f-min / $f-base * 1em)} + #{($f-max - $f-min) / $f-base} * (100vw - #{($w-min / $f-base * 1em)}) / #{($w-max - $w-min) / $f-base}); /* 1 */
		font-size: -webkit-calc(#{percentage($f-min / $f-base)} + #{($f-max - $f-min) / $f-base} * (100vw - #{percentage($w-min / $f-base)}) / #{($w-max - $w-min) / $f-base}); /* 2 */
		font-size: -ms-calc(#{($f-min / $f-base * 1em)} + #{($f-max - $f-min) / $f-base} * (100vw - #{($w-min / $f-base * 1em)}) / #{($w-max - $w-min) / $f-base}); /* 3 */
	}

	@media screen and (min-width: ($w-max / $f-base * 1em)) {
		font-size: ($f-max / $f-base * 1em);
	}
}

body {
	font-family: $primary-font;
	line-height: $base-line-height;
}

::selection {
  background: $black;
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $secondary-font;
	font-weight: normal;
	// line-height: 1.1;
}

h1,
.is-h1 {
	font-size: 3em; // +
}

h2,
.is-h2 {
	font-size: 2.15em;
	@include min-width(large) {
		font-size: 2.8125em;
	}
}

h3,
.is-h3 {
	font-size: 2em;
}

h4,
.is-h4 {
	font-size: 1.23em;
	@include min-width(large) {
		font-size: 1.4375em;
	}
}

h5,
.is-h5 {
	font-size: 1em; // +
	@include min-width(large) {
		font-size: 1.125em;
	}
}

a {
	color: inherit;
	text-decoration: none;
}


// h1 hero 23 = 1.438em