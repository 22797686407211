.c-page-module-hero {
	// align-content: stretch;
	// align-items: stretch;
	
	color: $white;
	// display: flex;
	// flex-direction: column;
	// justify-content: flex-start;

	overflow: hidden; //?
	z-index: 10;

	.overlay-open & {
		overflow: visible;
	}
}

.grid {
	align-items: stretch;
	display: flex;
    // flex: 0 1 auto;
    flex: 1;
	flex-direction: column;
	// flex-wrap: wrap;
	margin-bottom: -1em;

	// box-sizing:border-box;

    // flex: 0 0 auto;
    max-width: 100%;
	// min-width: 1000px !important;
	// background: green;

	& > :first-child {
		min-height: 6.623em;
	}

	@include min-width(medium) {
		align-items: center;
		flex: 1;
		flex-direction: row;
		margin-bottom: 0;

		& > :first-child {
			order: 1;
		}

		& > :last-child {
			order: -1;
		}
    }
}

.grid__item {
  	flex: 1 0 auto;
    padding-bottom: 1em;
    // flex-grow: 1;
    // box-sizing: border-box;
    display: block;

	@include min-width(medium) {
    flex: 1;
    	flex-basis: 33.33333333%;
    	flex-grow: 0;
   		max-width: 33.33333333%;
   		padding-bottom: 0;
    }
}

// https://codepen.io/shshaw/pen/OVGWLG
.c-page-module-hero__video {
	background-color: #000; 
	background-image: url('../videos/video-fallback.jpg'); 
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	bottom: 0;
	height: 100%; 
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;

	// &:before {
		// bottom: 0;
		// content: '';
		// height: 100%; 
		// overflow: hidden;
		// position: absolute;
		// top: 0;
		// width: 100%;
		// z-index: 1;
	// }

	video {
		display: none; 
		height: auto;
		left: 50%;
		min-height: 100%; 
		min-width: 100%; 
		pointer-events: none;
		position: fixed;
		top: 50%;
		transform: translate(-50%,-50%);    
		width: auto;
		z-index: 1;
		
		@include min-width(large) {
			display: inline-block;
		}
	}
}

.c-page-module-hero__wrapper { 
	display: flex;
	flex-direction: column;
	min-height: 100vh; 
	position: relative;
	z-index: 2;
}

.c-page-module-hero__main, 
.c-page-module-hero__footer {
	z-index: 2;
}

.c-page-module-hero__main {
	// align-items: stretch;
	// display: flex;
	// flex: 1;
	// flex-direction: row;
	// justify-content: flex-start;
	
	// display: flex;
	// flex-direction: row !important;
	display: flex;
	flex: 1;

	padding: 0 5em !important;
	text-align: center;
	
	@include min-width(medium) {
		padding-right: 1.563em !important;
	}

	.grid__item:first-child {
		pointer-events: none;
	}

	& > .grid {
		// min-height: 100vh;
	}
}

.c-page-module-hero__footer {
	text-align: center; 
	padding: 0 5em .750em;
	
	@include min-width(medium) {
		bottom: 0;
		left: 0;
		padding-bottom: 3.750em;
		position: absolute;		
		right: 0;
		text-align: left; 
	} 

	.overlay-open & {
		visibility: hidden;
	}
}

.c-page-module-hero__description {
	@include min-width(medium) {
		text-align: left;
	} 

	&__highlight {
		color: $vivid-orange;
		display: inline-block;
		margin-bottom: .5em;
		width: 100%;
	}

	a {
		border-bottom: 2px solid currentColor;
	}
}

.c-page-module-hero__logo {
	& > * {
		width: 7.3em;

		@include min-width(medium) {
			width: 14.37em;
		}
	}
}

.c-page-module-hero__navigation-link {
	border: 1px solid $white;
	cursor: pointer;
	display: inline-block;
	font-family: $secondary-font;
	height: 4.063em;
	left: 50%;
	line-height: 4.063em;
	pointer-events: visible;
	position: absolute;
	transform: translateX(-50%);
	width: 4.063em;
	z-index: 1001;

	@include min-width(medium) {
		float: right;
		left: auto;
		right: .78em;
		top: 50%;
		transform: translateY(-50%);
	} 

	@include max-width(medium) {
		margin: .78em auto;

		.overlay-open & {
			// background: $vivid-orange;
			// transition: background 2.2s;
		}
	}
}

.c-page-module-hero__scroll-hint {
	cursor: pointer;

	&__arrow {
		display: inline-block;
		min-width: 13px;
		width: 0.750em;
	}
	
	&__text {
		display: none;
		font-family: $secondary-font;

		@include min-width(medium) {
			display: inline-block;
			margin-left: 1.250em;
		} 
	}
}