.o-page-footer {
	color: $white;
	position: relative;
	z-index: 2;

	a[href^="mailto"] {
		border-bottom: 1px solid currentColor;

		&:hover {
			border-color: transparent;
		}
	}
}

.o-page-footer__header {
	background: $vivid-orange;
	display: none;
	padding: $box-padding-lg;
	padding-bottom: 0;

	@include min-width(large) {
		display: block;
	}

	svg {
		margin: $box-content-padding-lg;
		margin-bottom: 3em;
		width: 110px;
	}
}

.o-page-footer__box {
	padding: $box-padding-sm;

	@include min-width(large) {
		overflow: hidden;
		padding: $box-padding-lg;
		padding-bottom: 6.25em;
		padding-top: 0;
	}		

	& > * {     
		margin-right: 0;
		padding: $box-content-padding-sm;
		
		@include min-width(large) {
			margin-right: 3.250em;
			padding: $box-content-padding-lg;
			padding-right: 0;
		}
	}

	.o-page-footer__legal & { 
		padding-bottom: 2.3em;
		padding-top: 2.3em;

		&:first-child {
			padding-bottom: 0;
		}

		@include min-width(large) {
			padding-bottom: 2.8em;
			padding-top: 2.8em;
		}
	} 

	.o-page-footer__legal-links, 
	.o-page-footer__copyright {
		padding-top: 0;
		padding-bottom: 0;
	} 
}

.o-page-footer__contact,
.o-page-footer__legal {
	background: $vivid-orange;
	display: block; 
	// flex-direction: column;

	@include min-width(large) {
		display: flex; 
		flex-direction: row;
	}

	& > * {
		flex-grow: 1;
		flex-basis: 0;
	}
}

.o-page-footer__contact {

}

.o-page-footer__address {
	text-align: center; 

	@include max-width(large) {
		padding: 4.6em 0 1.6em;
	}

	@include min-width(large) {
		text-align: left;
	}

	h4 {
		display: none;
		margin-bottom: 3.5em;

		@include min-width(large) {
			display: block;
		}
	}

	p { 
		margin-bottom: 2.2em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.o-page-footer__legal {
	background: $black;
	color: $white;
	text-align: center;

	@include min-width(large) {
		min-height: 10em;
		text-align: left;
	}
}

.o-page-footer__legal-links {
	a {
		border-bottom: 1px solid currentColor;
		margin-right: 1.56em;

		&:hover {
			border-color: transparent;
		}
	}
}

.o-page-footer__copyright {
	@include min-width(large) {
		font-size: 0.8125em;
	}
}