/**
 * Fluid font size
 *
 * @author Philipp Kühn
 * @version 2016-03-27
 * @param {Number} $size
 * @param {Map} $options
 * @tutorial http://www.philipp-kuehn.com/beitrag/viewportbasierte-schriftgroessen/
 *
 * @example
 * .foo {
 * 	 @include fluid-size(20px, (
 *     min: 15px,
 *     max: 25px,
 *   ));
 * }
 */

$magic-size-ratio: 9;

@function magic-size($size) {
	$base: $size / ($size * 0 + 1);
	@return calc(#{$size - pow($base/$magic-size-ratio, 2)} + #{pow($base/($magic-size-ratio*3), 2)}vw);
}

@function magic-size-viewport($size, $search) {
	$base: $size / ($size * 0 + 1);
	$vw: ($search - ($size - pow($base/$magic-size-ratio, 2))) / pow($base/($magic-size-ratio*3), 2);
	@return $vw * 100;
}

@mixin fluid-size($size, $options: ()) {
	$magic-size: magic-size($size);
	font-size: $size;
	font-size: $magic-size;
	content: 'viewport-units-buggyfill; font-size: #{$magic-size}';

	@if map-has-key($options, 'min') {
		$min-size: map-get($options, 'min');
		$min-vw: magic-size-viewport($size, $min-size);
		@media (max-width: $min-vw) {
			font-size: #{$min-size};
		}
	}

	@if map-has-key($options, 'max') {
		$max-size: map-get($options, 'max');
		$max-vw: magic-size-viewport($size, $max-size);
		@media (min-width: $max-vw) {
			font-size: #{$max-size};
		}
	}
}