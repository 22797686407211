.nav {
	font-family: $secondary-font;
	font-size: 1.2em;
	line-height: 3;

	@media (max-height: 400px) {
		padding: 5em 0 2em;
	}

	@include min-width(medium) {
		font-size: 2.8em;
		line-height: 1.4;
		text-align: left;
	}

	&__list {
		list-style: none;
	}

	&__item {

	}
}

.nav__link {
	&:hover {
		color: $black;
	}
	
	&--language {
		color: #4a4a49;
	}
}