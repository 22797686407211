/**
 * Block for z-index management
 *
 * @param {String} $layer
 *
 * @example
 * .foo {
 * 	 z-index: z(modal);
 * }
 */

@function z($layer) {
	@if not map-has-key($z-layers, $layer) {
		@warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
	}

	@return map-get($z-layers, $layer);
}