* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: 0;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
}

*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 100%;
}

body {
	background-color: $black;
}

video {
	height: auto;
	width: 100%;
}

input,
textarea,
select,
button {
	background: none;
	border: none;
	color: inherit;
	font: inherit;
	line-height: inherit;
}