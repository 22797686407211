/**
 * Pixel to Rem
 *
 * @param {Number} $values
 *
 * @example
 * .foo {
 *   font-size: rem(14px);
 * }
 */

@function rem($values...) {
	$result: ();
	$separator: list-separator($values);
	@each $value in $values {
		@if type-of($value) == 'number' and unit($value) == 'px' {
			$result: append($result, $value / ($baseline / 1rem), $separator);
		} @else if type-of($value) == 'list' {
			$result: append($result, rem($value...), $separator);
		} @else {
			$result: append($result, $value, $separator);
		}
	}
	@return $result;
}