.overlay-open, 
.overlay-open body{ 
	margin: 0; 
	min-height: 100%; 
	overflow: hidden !important; 

	&.navigation, 
	&.navigation body {
		@include max-width(medium) {
			height: 100%; 

			.overlay__content {
				z-index: 1100;
			}
		}
	}
}

.overlay {
	background: $vivid-orange;
	box-sizing: border-box;
	height: 100%;
	left: 0;
	opacity: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	transform: scale(1.2);
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	visibility: hidden;
	width: 100%;
	z-index: 1000;

	&--dark {
		background: $black;
		color: $white;
	}

	&.open {
		opacity: 1;
		transform: scale(1);    
		transition: transform 0.4s, opacity 0.4s;
		transition-delay: 0s;
		visibility: visible;
	}

	.touchevents & {
		-webkit-overflow-scrolling: touch;

		// Reset effects
		display: none;
		opacity: 1;
		transition: none;
		transform: none;
		visibility: visible;
		
		&.open {
			display: block;
			visibility: visible;
			opacity: 1;
			transition-delay: 0s;
			transition: none;
			transform: none;    
		}
	}   
}

.overlay__top-bar {
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
	width: 100%;
	z-index: 12;
	
	@include min-width(small) {
		position: fixed;
	}
}

.overlay__close-button {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTggMTgiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzg2ODY4NiIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0uNS41bDE3IDE3TTE3LjUuNWwtMTcgMTciLz48L3N2Zz4=);
	background-repeat: no-repeat;
	display: inline-block;
	height: 2em;
	margin: 2em;
	width: 2.125em;
}

.overlay__content {
	display: table;
	height: 100%;
	margin: 0;
	min-height: 100%;
	overflow: auto;
	padding: 0;
	width: 100%;
	z-index: 11;

	&--vertical-center {
		display: table-cell;
		vertical-align: middle;
	}
}

.overlay__inner {
	@extend %clearfix;
	padding: 6.15em .77em 2em;
	
	@include min-width(medium) {
		padding: 5em 0 5em 5em;
		width: 50%;
	}

	.overlay__content--vertical-center & {
		padding: 0 5em;
	}
}   

.overlay__headline {
	margin: 0 0 1em;
}

.overlay__subheadline {
	margin: 0 0 .8em;
}

.overlay__paragraph {
	margin: 0 0 1.875em;

	&:last-child {
		margin-bottom: 0;
	}
}
