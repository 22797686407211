.o-page-module {
	background: $white;
	display: block; 
	// flex-direction: column;
	// flex-wrap: nowrap;
	overflow: hidden;
	position: relative;
	z-index: 1001;
	
	&.c-page-module-hero {
		display: flex; 

		flex-direction: column;
		flex-wrap: nowrap;
		overflow: hidden;
		position: relative;
	}

	@include min-width(large) {
		display: flex; 
		flex-direction: row;
	}

	.overlay-open &:not(.c-page-module-hero) {
		z-index: -1;
	}

	&:nth-child(even) {
		background: $light-grayish-orange;
	}

	&--full-height {
		min-height: 100vh;
	}

	&__box {
		flex-basis: 0;
		flex-grow: 1;
		padding: $box-padding-sm;

		&:last-child {
			padding-bottom: 6.15em;
		}

		@include min-width(large) {
			padding: $box-padding-lg;
		}

		&:last-child {
			@include min-width(large) {
				.o-page-module:nth-child(odd) & { 
					background: $light-grayish-orange;
				}

				.o-page-module:nth-child(even) & { 
					background: $white;
				}
			}
		}

		& > *:not(.u-flex-wrapper),     
		& > .u-flex-wrapper > * {     
   			margin-right: 0;
			padding: $box-content-padding-sm;
			
			@include min-width(large) {
   				margin-right: 3.250em;
				padding: $box-content-padding-lg;
				padding-right: 0;

				&.o-page-module__box__header--colored {
					padding-right: $box-content-padding-lg;
				}
			}
		}

		&--image {
			background-image: url('../img/management--landscape.jpg');
			background-size: cover;
			background-position: center;

			min-height: 200px;

			@include min-width(large) {
				background-image: url('../img/management--portrait.jpg');
			}
		}
	}

	&.is-visible {
		.o-page-module__header,
		.o-page-module__content {
			bottom: 0;
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

.o-page-module__header {
	display: flex;
	flex-direction: column;			
	justify-content: center;
	min-height: 7.692em;
	position: relative;

	@include min-width(large) {
		margin-bottom: 3.125em;
		min-height: 10.625em;
	}

	& > * { 
		position: relative;
		z-index: 3;
	}
	
	&:before, 
	&:after {
		background: $vivid-orange;
		bottom: 0;
		content: '';
		position: absolute;
		top: 0;
		z-index: 1;
	}

	&:before {
		@include min-width(large) {
			left: 0;
			margin-left: -10.6em;
			width: 10.6em;
		}
	}

	&:after {
		margin-right: -100vw;
		right: 0;
		width: 100vw;

		@include min-width(large) {
			background: 0;

			.o-page-module__box:last-child & { 
				background: $vivid-orange;
			}
		}
	}

	&--colored {
		&:after {
			background: 0;
		}

		@include min-width(large) {
			background: $vivid-orange;
			color: $white;
		}
	}

	h2 {
		letter-spacing: -0.025em;
	}
}

.o-page-module__content {
	transition-delay: .1s;
	
	h3 {
		margin-bottom: 1.875em;
		
		@include min-width(large) {
			margin-bottom: 2.5em;
		}
	}

	p {
		line-height: 1.6;
		padding-bottom: 1.875em;

		&:last-child {
			padding-bottom: 0;
		}
	}

	img {
		margin-bottom: 1.875em;
		max-width: 100%;
	}

	ul {
		font-family: $secondary-font;
		list-style-type: none;
		margin-bottom: 2.875em;
		margin-top: 1.875em;

		@include min-width(large) {
			min-height: 230px;
		}

		.o-page-module__box:first-child() & { 
			@include max-width(large) {
				// margin-bottom: 1.2em;
			}
		}


		li {
			line-height: 1.4;
			margin-bottom: 1.2em;
			padding-left: 1.250em;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				background: $vivid-orange;
				// bottom: 0;
				content: '';
				height: 1.15em;
				left: 0;
				position: absolute;
				top: 0;
				width: 0.313em;
			}
		}
	}
}

.o-page-module__header, 
.o-page-module__content {
	h3 {
		color: $vivid-orange;
		letter-spacing: 0.15em;
		text-transform: uppercase;
	}	

	.sd--show {
		@include min-width(large) {
			display: none;
		}
	}

	.sd--hide,
	&.sd--hide {
		@include max-width(large) {
			display: none;
		}
	}

	h4.sd--show {
		margin: 1.15em 0 1.5em; 
	}

	@include min-width(large) {
		opacity: 0;
		transform: translateY(200px);
		transition-duration: .8s;
		transition-property: transform, opacity;
	}
}